
































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class ProgramRestaurant extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.wantu.cn/6907157260cce3e5ea0b9e7c05c44c5c.png',
      'https://spics.wantu.cn/3eef551168028b65eae83e64fd30e94e.png',
      'https://spics.wantu.cn/2bf362a583a03d5a8b0a34e5b944d1cc.png',
      'https://spics.wantu.cn/f4087c29766c6328497ab523647798d0.png',
      'https://spics.wantu.cn/32c7058358dfe1edc5671fc4e6d7ddbe.png',
      'https://spics.wantu.cn/911c83b1f1c2197e0f6e878aba29bc3a.png',
    ]
  }
}
